import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as galleryjDl5RbNP1cMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as highlightsxqIDtGMklEMeta } from "/vercel/path0/pages/highlights.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91item_936E40VvcyMaMeta } from "/vercel/path0/pages/news/[item].vue?macro=true";
import { default as indexxeWP9UFz4RMeta } from "/vercel/path0/pages/news/index.vue?macro=true";
import { default as _91item_93VS61Z44hIgMeta } from "/vercel/path0/pages/packages/[item].vue?macro=true";
import { default as index1us1AaJFa5Meta } from "/vercel/path0/pages/packages/index.vue?macro=true";
import { default as reviewsp0OUKSelxuMeta } from "/vercel/path0/pages/reviews.vue?macro=true";
import { default as _91item_93bm9CCcgfkyMeta } from "/vercel/path0/pages/rooms/[item].vue?macro=true";
import { default as indexshyPbhYt8IMeta } from "/vercel/path0/pages/rooms/index.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
export default [
  {
    name: _91_46_46_46page_93plMEWPrPAfMeta?.name ?? "page___nl",
    path: _91_46_46_46page_93plMEWPrPAfMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93plMEWPrPAfMeta || {},
    alias: _91_46_46_46page_93plMEWPrPAfMeta?.alias || [],
    redirect: _91_46_46_46page_93plMEWPrPAfMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46page_93plMEWPrPAfMeta?.name ?? "page___en",
    path: _91_46_46_46page_93plMEWPrPAfMeta?.path ?? "/en/:page(.*)*",
    meta: _91_46_46_46page_93plMEWPrPAfMeta || {},
    alias: _91_46_46_46page_93plMEWPrPAfMeta?.alias || [],
    redirect: _91_46_46_46page_93plMEWPrPAfMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46page_93plMEWPrPAfMeta?.name ?? "page___de",
    path: _91_46_46_46page_93plMEWPrPAfMeta?.path ?? "/de/:page(.*)*",
    meta: _91_46_46_46page_93plMEWPrPAfMeta || {},
    alias: _91_46_46_46page_93plMEWPrPAfMeta?.alias || [],
    redirect: _91_46_46_46page_93plMEWPrPAfMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact___nl",
    path: contactJnsDpYVejzMeta?.path ?? "/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact___en",
    path: contactJnsDpYVejzMeta?.path ?? "/en/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact___de",
    path: contactJnsDpYVejzMeta?.path ?? "/de/kontakt",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: faqM4LN7jxnMuMeta?.name ?? "faq___nl",
    path: faqM4LN7jxnMuMeta?.path ?? "/faq",
    meta: faqM4LN7jxnMuMeta || {},
    alias: faqM4LN7jxnMuMeta?.alias || [],
    redirect: faqM4LN7jxnMuMeta?.redirect,
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqM4LN7jxnMuMeta?.name ?? "faq___en",
    path: faqM4LN7jxnMuMeta?.path ?? "/en/faq",
    meta: faqM4LN7jxnMuMeta || {},
    alias: faqM4LN7jxnMuMeta?.alias || [],
    redirect: faqM4LN7jxnMuMeta?.redirect,
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqM4LN7jxnMuMeta?.name ?? "faq___de",
    path: faqM4LN7jxnMuMeta?.path ?? "/de/faq",
    meta: faqM4LN7jxnMuMeta || {},
    alias: faqM4LN7jxnMuMeta?.alias || [],
    redirect: faqM4LN7jxnMuMeta?.redirect,
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: galleryjDl5RbNP1cMeta?.name ?? "gallery___nl",
    path: galleryjDl5RbNP1cMeta?.path ?? "/galerij",
    meta: galleryjDl5RbNP1cMeta || {},
    alias: galleryjDl5RbNP1cMeta?.alias || [],
    redirect: galleryjDl5RbNP1cMeta?.redirect,
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: galleryjDl5RbNP1cMeta?.name ?? "gallery___en",
    path: galleryjDl5RbNP1cMeta?.path ?? "/en/gallery",
    meta: galleryjDl5RbNP1cMeta || {},
    alias: galleryjDl5RbNP1cMeta?.alias || [],
    redirect: galleryjDl5RbNP1cMeta?.redirect,
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: galleryjDl5RbNP1cMeta?.name ?? "gallery___de",
    path: galleryjDl5RbNP1cMeta?.path ?? "/de/galerie",
    meta: galleryjDl5RbNP1cMeta || {},
    alias: galleryjDl5RbNP1cMeta?.alias || [],
    redirect: galleryjDl5RbNP1cMeta?.redirect,
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: highlightsxqIDtGMklEMeta?.name ?? "highlights___nl",
    path: highlightsxqIDtGMklEMeta?.path ?? "/highlights",
    meta: highlightsxqIDtGMklEMeta || {},
    alias: highlightsxqIDtGMklEMeta?.alias || [],
    redirect: highlightsxqIDtGMklEMeta?.redirect,
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: highlightsxqIDtGMklEMeta?.name ?? "highlights___en",
    path: highlightsxqIDtGMklEMeta?.path ?? "/en/highlights",
    meta: highlightsxqIDtGMklEMeta || {},
    alias: highlightsxqIDtGMklEMeta?.alias || [],
    redirect: highlightsxqIDtGMklEMeta?.redirect,
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: highlightsxqIDtGMklEMeta?.name ?? "highlights___de",
    path: highlightsxqIDtGMklEMeta?.path ?? "/de/highlights",
    meta: highlightsxqIDtGMklEMeta || {},
    alias: highlightsxqIDtGMklEMeta?.alias || [],
    redirect: highlightsxqIDtGMklEMeta?.redirect,
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___nl",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___en",
    path: indexe9Brt5DfdhMeta?.path ?? "/en",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___de",
    path: indexe9Brt5DfdhMeta?.path ?? "/de",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91item_936E40VvcyMaMeta?.name ?? "news-item___nl",
    path: _91item_936E40VvcyMaMeta?.path ?? "/blog/:item()",
    meta: _91item_936E40VvcyMaMeta || {},
    alias: _91item_936E40VvcyMaMeta?.alias || [],
    redirect: _91item_936E40VvcyMaMeta?.redirect,
    component: () => import("/vercel/path0/pages/news/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_936E40VvcyMaMeta?.name ?? "news-item___en",
    path: _91item_936E40VvcyMaMeta?.path ?? "/en/blog/:item()",
    meta: _91item_936E40VvcyMaMeta || {},
    alias: _91item_936E40VvcyMaMeta?.alias || [],
    redirect: _91item_936E40VvcyMaMeta?.redirect,
    component: () => import("/vercel/path0/pages/news/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_936E40VvcyMaMeta?.name ?? "news-item___de",
    path: _91item_936E40VvcyMaMeta?.path ?? "/de/blog/:item()",
    meta: _91item_936E40VvcyMaMeta || {},
    alias: _91item_936E40VvcyMaMeta?.alias || [],
    redirect: _91item_936E40VvcyMaMeta?.redirect,
    component: () => import("/vercel/path0/pages/news/[item].vue").then(m => m.default || m)
  },
  {
    name: indexxeWP9UFz4RMeta?.name ?? "news___nl",
    path: indexxeWP9UFz4RMeta?.path ?? "/blog",
    meta: indexxeWP9UFz4RMeta || {},
    alias: indexxeWP9UFz4RMeta?.alias || [],
    redirect: indexxeWP9UFz4RMeta?.redirect,
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexxeWP9UFz4RMeta?.name ?? "news___en",
    path: indexxeWP9UFz4RMeta?.path ?? "/en/blog",
    meta: indexxeWP9UFz4RMeta || {},
    alias: indexxeWP9UFz4RMeta?.alias || [],
    redirect: indexxeWP9UFz4RMeta?.redirect,
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexxeWP9UFz4RMeta?.name ?? "news___de",
    path: indexxeWP9UFz4RMeta?.path ?? "/de/blog",
    meta: indexxeWP9UFz4RMeta || {},
    alias: indexxeWP9UFz4RMeta?.alias || [],
    redirect: indexxeWP9UFz4RMeta?.redirect,
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _91item_93VS61Z44hIgMeta?.name ?? "packages-item___nl",
    path: _91item_93VS61Z44hIgMeta?.path ?? "/arrangementen/:item()",
    meta: _91item_93VS61Z44hIgMeta || {},
    alias: _91item_93VS61Z44hIgMeta?.alias || [],
    redirect: _91item_93VS61Z44hIgMeta?.redirect,
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_93VS61Z44hIgMeta?.name ?? "packages-item___en",
    path: _91item_93VS61Z44hIgMeta?.path ?? "/en/special-offers/:item()",
    meta: _91item_93VS61Z44hIgMeta || {},
    alias: _91item_93VS61Z44hIgMeta?.alias || [],
    redirect: _91item_93VS61Z44hIgMeta?.redirect,
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_93VS61Z44hIgMeta?.name ?? "packages-item___de",
    path: _91item_93VS61Z44hIgMeta?.path ?? "/de/arrangements/:item()",
    meta: _91item_93VS61Z44hIgMeta || {},
    alias: _91item_93VS61Z44hIgMeta?.alias || [],
    redirect: _91item_93VS61Z44hIgMeta?.redirect,
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: index1us1AaJFa5Meta?.name ?? "packages___nl",
    path: index1us1AaJFa5Meta?.path ?? "/arrangementen",
    meta: index1us1AaJFa5Meta || {},
    alias: index1us1AaJFa5Meta?.alias || [],
    redirect: index1us1AaJFa5Meta?.redirect,
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: index1us1AaJFa5Meta?.name ?? "packages___en",
    path: index1us1AaJFa5Meta?.path ?? "/en/special-offers",
    meta: index1us1AaJFa5Meta || {},
    alias: index1us1AaJFa5Meta?.alias || [],
    redirect: index1us1AaJFa5Meta?.redirect,
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: index1us1AaJFa5Meta?.name ?? "packages___de",
    path: index1us1AaJFa5Meta?.path ?? "/de/arrangements",
    meta: index1us1AaJFa5Meta || {},
    alias: index1us1AaJFa5Meta?.alias || [],
    redirect: index1us1AaJFa5Meta?.redirect,
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: reviewsp0OUKSelxuMeta?.name ?? "reviews___nl",
    path: reviewsp0OUKSelxuMeta?.path ?? "/beoordelingen",
    meta: reviewsp0OUKSelxuMeta || {},
    alias: reviewsp0OUKSelxuMeta?.alias || [],
    redirect: reviewsp0OUKSelxuMeta?.redirect,
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: reviewsp0OUKSelxuMeta?.name ?? "reviews___en",
    path: reviewsp0OUKSelxuMeta?.path ?? "/en/reviews",
    meta: reviewsp0OUKSelxuMeta || {},
    alias: reviewsp0OUKSelxuMeta?.alias || [],
    redirect: reviewsp0OUKSelxuMeta?.redirect,
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: reviewsp0OUKSelxuMeta?.name ?? "reviews___de",
    path: reviewsp0OUKSelxuMeta?.path ?? "/de/rezensionen",
    meta: reviewsp0OUKSelxuMeta || {},
    alias: reviewsp0OUKSelxuMeta?.alias || [],
    redirect: reviewsp0OUKSelxuMeta?.redirect,
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: _91item_93bm9CCcgfkyMeta?.name ?? "rooms-item___nl",
    path: _91item_93bm9CCcgfkyMeta?.path ?? "/kamers/:item()",
    meta: _91item_93bm9CCcgfkyMeta || {},
    alias: _91item_93bm9CCcgfkyMeta?.alias || [],
    redirect: _91item_93bm9CCcgfkyMeta?.redirect,
    component: () => import("/vercel/path0/pages/rooms/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_93bm9CCcgfkyMeta?.name ?? "rooms-item___en",
    path: _91item_93bm9CCcgfkyMeta?.path ?? "/en/rooms/:item()",
    meta: _91item_93bm9CCcgfkyMeta || {},
    alias: _91item_93bm9CCcgfkyMeta?.alias || [],
    redirect: _91item_93bm9CCcgfkyMeta?.redirect,
    component: () => import("/vercel/path0/pages/rooms/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_93bm9CCcgfkyMeta?.name ?? "rooms-item___de",
    path: _91item_93bm9CCcgfkyMeta?.path ?? "/de/zimmer/:item()",
    meta: _91item_93bm9CCcgfkyMeta || {},
    alias: _91item_93bm9CCcgfkyMeta?.alias || [],
    redirect: _91item_93bm9CCcgfkyMeta?.redirect,
    component: () => import("/vercel/path0/pages/rooms/[item].vue").then(m => m.default || m)
  },
  {
    name: indexshyPbhYt8IMeta?.name ?? "rooms___nl",
    path: indexshyPbhYt8IMeta?.path ?? "/kamers",
    meta: indexshyPbhYt8IMeta || {},
    alias: indexshyPbhYt8IMeta?.alias || [],
    redirect: indexshyPbhYt8IMeta?.redirect,
    component: () => import("/vercel/path0/pages/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: indexshyPbhYt8IMeta?.name ?? "rooms___en",
    path: indexshyPbhYt8IMeta?.path ?? "/en/rooms",
    meta: indexshyPbhYt8IMeta || {},
    alias: indexshyPbhYt8IMeta?.alias || [],
    redirect: indexshyPbhYt8IMeta?.redirect,
    component: () => import("/vercel/path0/pages/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: indexshyPbhYt8IMeta?.name ?? "rooms___de",
    path: indexshyPbhYt8IMeta?.path ?? "/de/zimmer",
    meta: indexshyPbhYt8IMeta || {},
    alias: indexshyPbhYt8IMeta?.alias || [],
    redirect: indexshyPbhYt8IMeta?.redirect,
    component: () => import("/vercel/path0/pages/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: sitemapmtRscZaA8vMeta?.name ?? "sitemap___nl",
    path: sitemapmtRscZaA8vMeta?.path ?? "/sitemap",
    meta: sitemapmtRscZaA8vMeta || {},
    alias: sitemapmtRscZaA8vMeta?.alias || [],
    redirect: sitemapmtRscZaA8vMeta?.redirect,
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapmtRscZaA8vMeta?.name ?? "sitemap___en",
    path: sitemapmtRscZaA8vMeta?.path ?? "/en/sitemap",
    meta: sitemapmtRscZaA8vMeta || {},
    alias: sitemapmtRscZaA8vMeta?.alias || [],
    redirect: sitemapmtRscZaA8vMeta?.redirect,
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapmtRscZaA8vMeta?.name ?? "sitemap___de",
    path: sitemapmtRscZaA8vMeta?.path ?? "/de/sitemap",
    meta: sitemapmtRscZaA8vMeta || {},
    alias: sitemapmtRscZaA8vMeta?.alias || [],
    redirect: sitemapmtRscZaA8vMeta?.redirect,
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  }
]